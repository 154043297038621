<template>
  <div class="modal-trial-review">
    <sweet-modal ref="modal" width="600px" overlay-theme="dark" @close="onClose">
      <div class="trial-modal-content" v-if="product">
        <div class="step step1" v-if="step === 1">
          <img class="img-product" :src="product.img">
          <div class="body4 sub">{{ product.name }}</div>
          <div class="size-20 main" style="margin-top:20px">플랫폼파이 테마<br>만족스러우신가요?</div>

          <div class="button-group">
            <button class="button is-primary" @click="clickResult(2)"><span class="body4-medium">네! 좋아요</span></button>
            <button class="button body4-medium" @click="clickResult(4)"><span class="body4-medium">그냥 그래요</span></button>
          </div>
        </div>
        <div class="step step2" v-else-if="step === 2">
          <template v-if="serviceInfo.service_order.trial_event">
            <div class="subtitle4 main">감사합니다!</div>
            <div class="body2 main">그렇다면 칭찬 후기를 남겨주세요.</div>
          </template>
          <div v-else class="subtitle4 main">후기를 남겨주시면 <br>무료체험 <span class="primary">7</span>일을 더 드려요.</div>
          <div class="step-label">별점</div>
          <star-rating :rating="value.rate"
                       @rating-selected="res => value.rate = res"
                       active-color="#ff5722"
                       :star-size="32"
                       :increment="0.5"
                       :padding="5"
                       :star-points="[20,34, 30,40, 31,40, 32,40, 33,38, 33,37, 31,25, 39,18, 40,16, 40,15, 39,14, 38,13, 27,12, 22,1, 21,0, 20,0, 19,0, 18,1, 13,12, 2,13, 1,14, 0,15, 0,16, 1,18, 9,25, 7,37, 7,38, 8,40, 9,40, 10,40, 20,34]"
                       :show-rating="false"></star-rating>
          <div class="step-label">후기내용</div>
          <textarea rows="5" placeholder="후기를 입력해주세요." v-model="value.content"
                    @keyup="e => value.content = e.target.value"></textarea>
          <div class="step-label">후기사진 (최대 5장)</div>
          <input-img-uploader-array :value.sync="value.imgs"></input-img-uploader-array>
          <div class="button-group">
            <button class="button is-primary" @click="clickReview">완료</button>
          </div>
        </div>
        <div class="step step3" v-else-if="step === 3">
          <div class="subtitle4 main">후기작성이 완료되었습니다.<br>무료체험 <span class="primary">7</span>일을 더 드렸어요.</div>
          <div class="button-group">
            <button class="button is-primary" @click="clickDone">완료</button>
          </div>
        </div>
        <div class="step step3" v-else-if="step === 4">
          <template v-if="serviceInfo.service_order.trial_event">
            <div class="subtitle4 main">죄송합니다.</div>
            <div class="body2 main">불편사항이나 건의사항을 알려주세요.</div>
          </template>
          <div v-else
               class="subtitle4 main">불편사항이나 건의사항을 알려주시면<br>무료체험 <span class="primary">7</span>일을 더 드려요.</div>
          <div class="button-group">
            <button class="button is-primary" @click="clickInquiry">1:1 문의하기</button>
            <button class="button is-gray" @click="clickDone">다음에</button>
          </div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
  import StarRating from "vue-star-rating/src/star-rating";
  import { SweetModal } from "sweet-modal-vue";
  import InputImgUploaderArray from "../module/InputImgUploaderArray";
  import ServiceAdminAPIMixin from "../../mixins/ServiceAdminAPIMixin";
  export default {
    name: "TrialReviewModal",
    mixins: [
      ServiceAdminAPIMixin
    ],
    components: {
      InputImgUploaderArray,
      SweetModal,
      StarRating
    },
    props: {
      serviceInfo: {
        type: Object
      }
    },
    created() {
      this.product = this.serviceInfo.service_order.products[0];
    },
    data() {
      return {
        product: undefined,
        step: 1,
        value: {
          rate: 0,
          content: '',
          imgs: []
        }
      }
    },
    methods: {
      open() {
        this.setAuth();
        this.step = 1;
        this.$refs.modal.open();
      },
      onClose() {
        if(this.step === 3) {
          this.reviewStatus();
        }
      },
      reviewStatus() {
        setTimeout(()=>{
          this.serviceInfo.service_order.review = true;
        },300);
      },
      clickResult(step) {
        this.step = step;
      },
      valid() {
        let msg = '';
        if(this.value.content.trim() === '') {
          msg = '내용을 입력해주세요.';
        }
        if(msg !== '') {
          this.toast(msg);
        }

        return msg === '';
      },
      clickReview() {
        if(!this.valid()) return;
        let url = `service/${this.serviceInfo.id}/product/${this.product.id}/review`;
        let param = this.cloneItem(this.value);
        param.product_id = this.product.id;
        param.imgs = param.imgs.filter(i => i !== '');
        param.service_id = this.serviceInfo.id;
        this.$store.commit('setLoading', true);
        this.request.serviceAdmin.post(url, param).then(res => {
          this.$store.commit('setLoading', false);
          if(res.status === 200) {
            if(res.data.add_trial) {
              this.clickResult(3);
            } else {
              this.toast('후기가 등록되었습니다.');
              this.clickDone();
              this.reviewStatus();
            }
          }
        }).catch((e)=> {
          console.log(e);
          this.$store.commit('setLoading', false);
        });
      },
      clickDone() {
        this.$refs.modal.close();
      },
      clickInquiry() {
        this.clickDone();
        this.reviewStatus();
        window.open(`${this.serviceInfo.domain}/office/admin/launchpack/inquiry/customer/create?cat=13`,'_blank');
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .step
    display flex
    align-items center
    flex-direction column
    text-align center

  .step1
    .img-product
      height 80px

  .step2
    .step-label
      font-size 14px
      margin 20px 0 4px 0
      color #4f4f4f
    textarea
      width 100%
      height 140px
      border 1px solid #e3e3e3
      border-radius 8px
      padding 12px 16px

  .button-group
    margin-top 40px
    display grid
    grid-gap 8px
    .button
      width 300px
      height 44px !important

</style>

<style lang="stylus">

</style>
